
<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <!-- Add New -->
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('tour_management.visited') + ' ' + $t('globalTrans.list')}}</h4>
          </template>
          <!-- /Add New -->
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">

                  <!-- Table -->
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>

                    <template v-slot:cell(status)="data">
                          {{ getStatus(data.item.status) }}
                    </template>

                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                    </template>
                  </b-table>

                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-5" size="lg" :title="$t('pp_visit_request.visit_request')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="detailItem" :key="detailItemId"></Details>
    </b-modal>

    <b-modal id="modal-3" size="lg" :title="formTitle"  :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>

  </b-container>
</template>
<script>
import Form from './Form'
import Details from './Only-Details'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { phyVisitReqListApi, phyVisitAdminVisitedApi, pendingNothiVisitRequestList, reSentNothiVisitRequestList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'

export default {
  mixins: [ModalBaseMasterList],
  components: {
     Form, Details
  },
  data () {
    return {
      search: {
        org_id: this.$store.state.dataFilters.orgId ? this.$store.state.dataFilters.orgId : 0,
        status: 4
      },
      editItemId: 0,
      itemId: 0,
      // rows: [],
      detailItem: '',
      detailItemId: ''
    }
  },
  computed: {
    formTitle () {
        if (this.editItemId === 0) {
            return this.$t('pp_visit_request.visit_request') + ' ' + this.$t('globalTrans.entry')
        } else {
            return this.$t('pp_visit_request.visit_request') + ' ' + this.$t('globalTrans.modify')
        }
    },
    requestTypeList: function () {
        const list = [
        { value: 1, text_en: 'Individual', text_bn: 'স্বতন্ত্র' },
        { value: 2, text_en: 'Institutional', text_bn: 'প্রাতিষ্ঠানিক' }
        ]
        return list
    },
    columns () {
      // Table Column Labels
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.organization'), class: 'text-center' },
          { label: this.$t('tour_management.request_id'), class: 'text-center' },
          { label: this.$t('pp_visit_request.request_type'), class: 'text-center' },
          { label: this.$t('tour_management.contact_person'), class: 'text-center' },
          { label: this.$t('tour_management.mobile_no'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('pp_visit_request.notes'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'request_id' },
          { key: 'request_type_bn' },
          { key: 'contact_person_name' },
          { key: 'mobile_no' },
          { key: 'status' },
          { key: 'notes' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'request_id' },
          { key: 'request_type' },
          { key: 'contact_person_name' },
          { key: 'mobile_no' },
          { key: 'status' },
          { key: 'notes' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  created () {
    this.loadData()
  },
  watch: {
  },
  methods: {
    addForm () {
      this.$router.push({ path: 'add-disease' })
    },
    details (item) {
      this.detailItem = item
      this.itemId = item.id
      this.detailItemId = item.id
    },
    edit (item) {
      this.editItemId = item.id
    },
    searchData () {
      this.loadData()
    },
    serVisited (item) {
       this.changeStatus(virtualMuseumServiceBaseUrl, phyVisitAdminVisitedApi, item)
    },
    // remove (item) {
    //   this.changeStatus(virtualMuseumServiceBaseUrl, diseaseManageToggleStatusApi, item)
    // },
    finalSave (item) {
        this.toggleStatusCustom2(virtualMuseumServiceBaseUrl, pendingNothiVisitRequestList, item)
      },
      finalSaveResend (item) {
        this.toggleStatusCustom2(virtualMuseumServiceBaseUrl, reSentNothiVisitRequestList, item)
      },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('elearning_venue.are_you_sure_forward_to_nothi'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        // RestApi.getData(baseUrl, uri, item).then(response => {
        RestApi.postData(baseUrl, uri, item).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(virtualMuseumServiceBaseUrl, phyVisitReqListApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
            let orgList = {}
            const listData = data.map(item => {
                orgList = this.$store.state.commonObj.organizationProfileList.find(org => org.value === item.org_id)
               const rqType = this.requestTypeList.find(rql => rql.value === item.request_type)
            const rqTypeObj = {
            request_type: rqType !== undefined ? rqType.text_en : '',
            request_type_bn: rqType !== undefined ? rqType.text_bn : ''
             }
               const data = {
                    org_name: orgList !== undefined ? orgList.text_en : '',
                    org_name_bn: orgList !== undefined ? orgList.text_bn : ''
                }
                const timeSlotObj = {
                    time_slot: item.opening_time + '---' + item.closeing_time
                }
                return Object.assign({}, item, rqTypeObj, timeSlotObj, data)
            })
            return listData
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('budget.pending')
      } else if (status === 2) {
        return this.$t('pp_visit_request.reviewed')
      } else if (status === 3) {
        return this.$t('budget.approve')
      } else if (status === 4) {
        return this.$t('pp_visit_request.visited')
      } else if (status === 5) {
        return this.$t('tour_management.reject')
      }
    }
  }
}
</script>
